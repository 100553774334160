<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import helperFile from "../../../helpers/helper";

/**
 * Advanced-table component
 */
export default {
  page: {
    title: "Approve Restaurant Branch Table",
    meta: [
      {
        name: "description",
        content: appConfig.description
      }
    ]
  },
  components: { Layout, PageHeader },
  data() {
    return {
      tableData: [],

      title: "Approve Restaurant Branch",
      items: [
        {
          text: "View"
        },
        {
          text: "Approve Restaurant Branch",
          active: true
        }
      ],
      confirmMsg: "",
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      filterOn: [],
      sortBy: "age",
      sortDesc: false,
      fields: [
        {
          key: "Action",

          sortable: false
        },
        {
          key: "name",
          sortable: true
        },
        {
          key: "address",
          sortable: true
        },
        {
          key: "Location",
          sortable: true
        },
        // {
        //   key: "startTime",
        //   sortable: true,
        // },
        // {
        //   key: "endTime",
        //   sortable: true,
        // },
        {
          key: "avgPreparationTime",
          sortable: true
        },
        {
          key: "restCharges",
          sortable: true,
          tdClass: "align-right"
        },
        {
          key: "createdBy",
          sortable: true
        },
        {
          key: "created",
          sortable: true
        }
      ]
    };
  },
  computed: {
    /**
     * Total no. of records
     */
    rows() {
      return this.tableData.length;
    }
  },
  mounted() {
    // Set the initial number of items
    this.totalRows = this.items.length;
    this.readApprovalRestaurants();
    this.substring = helperFile.substringFunction;
  },
  methods: {
    /**
     * Search the table data with search input
     */
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },

    readApprovalRestaurants() {
      this.axios
        .post(this.$loggedRole+"/viewAllRestaurantBranchForApproval", {
          empTypeID: this.$storageData.profile.empTypeID,
          approverLvl: this.$storageData.profile.approverLvl
            ? this.$storageData.profile.approverLvl
            : 0
        })
        .then(response => {
          //Then injecting the result to datatable parameters.

          this.tableData = response.data.data;
          //console.log((response));
        });
    },

    approveRestaurant(restBranchID) {
      this.confirmMsg = "Are you sure you want to approve this restaurant?";
      if (
        this.$storageData.profile.empTypeID == 15 ||
        (this.$storageData.profile.empTypeID == 1 && this.$storageData.profile.approverLvl == 1)
      ) {
        this.confirmMsg = "Are you sure you want to make this restaurant live?";
      }
      if (confirm(this.confirmMsg)) {
        this.axios
          .post(this.$loggedRole+"/approveRestaurantBranch", {
            approverLvl: this.$storageData.profile.approverLvl
              ? this.$storageData.profile.approverLvl
              : 0,
            empTypeID: this.$storageData.profile.empTypeID,
            restBranchID: restBranchID
          })
          .then(() => {
            this.readApprovalRestaurants();
          });
      }
    },
    rejectRestaurant(restBranchID) {
      this.confirmMsg = "Are you sure you want to reject this restaurant?";
      if (confirm(this.confirmMsg)) {
        this.axios
          .post(this.$loggedRole+"/rejectRestaurantBranch", {
            approverLvl: this.$storageData.profile.approverLvl
              ? this.$storageData.profile.approverLvl
              : 0,
            empTypeID: this.$storageData.profile.empTypeID,
            restBranchID: restBranchID
          })
          .then(() => {
            this.readApprovalRestaurants();
          })
          .catch(error => {
            alert(error.response.data.message);
          });
      }
    }
  },
  middleware: "authentication"
};
</script>

<template>
  <Layout>
    <PageHeader :items="items" :title="title" />
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="row mt-4">
              <div
                v-if="this.$storageData.login_type == 1"
                class="col-sm-12 col-md-4"
                style="margin-bottom:15px;"
              >
                <div class="btn-group" role="group">
                  <download-excel
                    :data="tableData"
                    class="btn btn-themeBrown"
                    name="restaurant_Branches.xls"
                    type="button"
                    worksheet="Restaurant Branches"
                  >
                    Excel
                  </download-excel>
                </div>
              </div>
              <div class="col-sm-12 col-md-4">
                <div id="tickets-table_length" class="dataTables_length">
                  <label class="d-inline-flex align-items-center">
                    Show &nbsp;&nbsp;
                    <b-form-select
                      v-model="perPage"
                      :options="pageOptions"
                      size="sm"
                      style="margin-left:5px; margin-right:5px"
                    ></b-form-select
                    >&nbsp;&nbsp;&nbsp; entries
                  </label>
                </div>
              </div>
              <!-- Search -->
              <div class="col-sm-12 col-md-4 row">
                <div
                  id="tickets-table_filter"
                  class="dataTables_filter text-md-end"
                >
                  <label class="d-inline-flex align-items-center">
                    Search:
                    <b-form-input
                      v-model="filter"
                      class="form-control form-control-sm ms-2"
                      placeholder="Search..."
                      type="search"
                    ></b-form-input>
                  </label>
                </div>
              </div>
              <!-- End search -->
            </div>
            <!-- Table -->
            <div class="table-responsive mb-0 table-striped">
              <b-table
                :current-page="currentPage"
                :fields="fields"
                :filter="filter"
                :filter-included-fields="filterOn"
                :items="tableData"
                :per-page="perPage"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                bordered
                hover
                outlined
                responsive
                striped
                @filtered="onFiltered"
              >
                <template v-slot:cell(Action)="data">
                  <router-link
                    :to="{
                      name: 'restaurantDetails',
                      params: { id: data.item.restBranchID, type: 'edit' }
                    }"
                  >
                    <button
                      class="btn btn-themeOrange mb-1"
                      style="padding: 0px 7px;"
                    >
                      View
                    </button>
                  </router-link>
                  <br />
                  <button
                    v-if="data.item.isActive == 3"
                    class="btn btn-themeYellow"
                    style="padding: 0px 7px;"
                    @click="approveRestaurant(data.item.restBranchID)"
                  >
                    Approve
                  </button>
                  <button
                    v-else
                    class="btn btn-themeYellow"
                    style="padding: 0px 7px;"
                    @click="approveRestaurant(data.item.restBranchID)"
                  >
                    Make Live
                  </button>
                  <br />
                  <button
                    v-if="data.item.isActive != 1"
                    class="btn btn-danger m-1"
                    style="padding: 0px 7px;"
                    @click="rejectRestaurant(data.item.restBranchID)"
                  >
                    Reject
                  </button>
                  <!-- <i class="mdi mdi-delete" title="Delete" style="font-size: 19px;"></i> -->
                </template>

                <template v-slot:cell(address)="data">
                  <span :title="data.item.address">{{
                    substring(data.item.address)
                  }}</span>
                </template>
                <template v-slot:cell(Location)="data">
                  <span>{{ data.item.branchName }}</span>
                </template>
                <template v-slot:cell(restCharges)="data">
                  <span v-if="data.item.restCharges > 0"
                    >₹{{ data.item.restCharges }}</span
                  >
                  <span v-else> 0 </span>
                </template>

                <template v-slot:cell(avgPreparationTime)="data">
                  <span>{{ data.item.avgPreparationTime }} mins</span>
                </template>
              </b-table>
            </div>
            <div class="row">
              <div class="col">
                <div
                  class="dataTables_paginate paging_simple_numbers float-end"
                >
                  <ul class="pagination pagination-rounded mb-0">
                    <!-- pagination -->
                    <b-pagination
                      v-model="currentPage"
                      :per-page="perPage"
                      :total-rows="rows"
                    ></b-pagination>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>
